/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "variables";

html,
body {
  font-family: $theme-font-family;
}

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

textarea::-webkit-scrollbar {
  display: none;
}

// .nav-tabs, .nav-pills {
//   margin-bottom: 0 !important;
// }
.nav-tabs {
  border-bottom: 0;
}

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
//   border:1px solid #0E4AB3;
//   border-radius: 10px;
//   color:white;
//   text-align: center;
//   background-color: #0E4AB3;
// }
.nav-tabs .nav-link {
  color: #a1a1a1;
  text-align: center;
  border: none;
  font-family: oswald;
  letter-spacing: 0;
}

ul[role="tablist"] li {
  width: 15.9rem;
}

// ul[role='tablist']{
//   border-bottom: 1px solid #A1A1A1;
//   border-radius: 10px;
//   box-shadow:  0 1px #A1A1A1;
// }

.nav-link {
  display: block;
  padding: 0.3rem 1rem;
}

//sweet alert
.swal2-popup {
  width: auto !important;

  .swal2-header {
    padding: 0 1em !important;

    h2.swal2-title {
      font-size: 16px !important;
      font-weight: 100 !important;
    }
  }

  .swal2-actions {

    .swal2-confirm.swal2-styled,
    .swal2-cancel.swal2-styled {
      padding: 0.25em 0.75rem !important;
      font-size: 12px !important;
    }

    .swal2-confirm.swal2-styled {
      background-color: #0e4ab3 !important;
    }

    .swal2-cancel.swal2-styled {
      background-color: #d33 !important;
    }
  }
}

//responsive css
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
  width: 6rem;
  height: 100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper {
  display: flex !important;
  padding: 0.5rem !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper .a .img {
  padding-left: 0.5rem !important;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}


//***Modal***
.modal-content {
  // border-radius: 1rem;
  padding: 1.5rem;
  font-family: $theme-font-family;

  .modal-header {
    padding: 0;
    border-bottom: 1px solid $theme-color-orange;

    .modal-title {
      color: $theme-color-orange;
      font-size: 16px;
      letter-spacing: 0.01rem;
      padding-bottom: 0.5rem;
      font-family: $theme-font-family-bold;
    }

    .btn-close {
      cursor: pointer;
      color: $theme-color-orange;
      padding: 10px;

      &:hover {
        background-color: rgba($theme-color-gray-1, 0.5);
      }
    }
  }

  .modal-body {
    padding: 1rem 0;

    .confirmation-text {
      font-size: 16px;
      text-align: center;
      color: $theme-color-orange;
      font-family: $theme-font-family-serial-regular;

      span {
        color: $theme-color-gray;
      }
    }

    .form-label {
      font-size: 12px;
      color: $theme-color-gray;
      margin-bottom: 0.75rem;
      display: block;
    }

    .form-control {
      font-size: 11px;
      padding: 0.25rem 0.5rem;
      margin-top: 0.25rem;
      border-color: #dee2e6;

      &:disabled {
        background-color: #fff;
      }
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0;

    >* {
      margin: 0;
    }

    button {
      border: none;
      border-radius: 8px;
      padding: 5px 24px;
      font-size: 12px;
      line-height: 1.6 !important;
      letter-spacing: 1px;
      font-family: $theme-font-family-serial-medium;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }

      &.btn-orange {
        background-color: $theme-color-orange;
        color: #fff;

        &:not(:disabled):hover {
          outline: 0.5px solid $theme-color-orange;
        }
      }

      &.btn-outline-orange {
        background-color: #fff;
        color: $theme-color-orange;
        outline: 1px solid $theme-color-orange !important;

        &:not(:disabled):hover {
          background-color: $theme-color-orange;
          color: #fff;
        }
      }

      &.btn-gray {
        background-color: $theme-color-gray;
        color: #fff;

        &:not(:disabled):hover {
          outline: 0.5px solid $theme-color-gray;
        }
      }
    }
  }
}

//***/Modal***

//***form-wrapper***
.form-wrapper {
  height: calc(100vh - 215px);
  overflow-x: hidden;
  overflow-y: auto;

  .form-header {
    padding: 0 0 5px 0;
    border-bottom: 1px solid $theme-color-orange;
    margin-bottom: 20px;

    .form-title {
      color: $theme-color-orange;
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 0.5rem;
      font-family: $theme-font-family;
    }
  }

  .form-content {
    .form-label {
      font-size: 12px;
      color: $theme-color-gray;
      margin-bottom: 20px;
      display: block;
    }

    .form-control {
      font-size: 11px;
      padding: 0.25rem 0.5rem;
      margin-top: 0.25rem;
      border-color: #dee2e6;

      &:disabled {
        background-color: #fff;
      }
    }

    button {
      border: none !important;
      border-radius: 0.25rem !important;
      padding: 0.35rem 1rem !important;
      font-size: 12px !important;
      line-height: 1.6 !important;
      font-family: $theme-font-family !important;
      transition: box-shadow 0.15s ease;

      &.btn-orange {
        background-color: $theme-color-orange;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $theme-color-orange;
        }
      }

      &.btn-gray {
        background-color: $theme-color-gray;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $theme-color-gray;
        }
      }
    }
  }
}

//***/form-wrapper***

//***Form - password-show/hide***
.password-showhide-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 0.25rem;

  .form-control {
    margin-top: 0 !important;
  }

  .btn-showHide {
    position: absolute;
    border: none;
    background-color: transparent;
    margin-right: 5px;
    font-size: 0.6rem;
  }
}

//***/Form - password-show/hide***

//***buttons***
.btn-action {
  font-size: 9px;
  line-height: 8px;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: transparent;
  transition: 0.3s ease;
}

.btn-action-orange {
  @extend .btn-action;
  border-color: $theme-color-orange;
  color: $theme-color-orange;

  &:hover {
    background-color: $theme-color-orange;
    color: #fff;
  }
}

.btn-action-dark {
  @extend .btn-action;
  border: 1px solid $theme-color-gray-light;
  color: $theme-color-gray-light;

  &:hover {
    background-color: $theme-color-gray-light;
    color: #fff;
  }
}

.btn-action-gray-filled {
  @extend .btn-action;
  border: none;
  background-color: $theme-color-gray;
  color: #fff;
  border-radius: 4px !important;
  transition: 0.15s ease-in-out;  

  &:hover {
    // background-color: rgba($theme-color-gray, 0.9);
    font-size: 8.5px;
  }
}

//***/buttons***

//***toast messages***
.toast-container .toast-error {
  background-color: #d33 !important;
}

//***/toast messages***

//***custom ngx datatable component***
.ngxdt {
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  height: 100%;

  >* {
    font-family: $font-roboto;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
  }

  .ngxdt-header {
    // display: flex;
    // justify-content: flex-end;
    // //flex-wrap: unset !important;
    // min-height: 26px;
    // height: 26px;
    // gap: 0px;
    display: flex;
    justify-content: flex-end;
    //flex-wrap: wrap;
    width: 100%;
    min-height: 26px;
    height: 26px;
    column-gap: 10px;
    row-gap: 15px;

    .ngxdt-buttons {
      button {
        border: none;
        border-radius: $border-radius-2 !important;
        padding: 0.25rem 1rem;
        font-size: 0.75rem;
        margin: auto 15px;

        &.btn-add {
          background-color: $theme-color-orange;
          font-weight: 400;
          font-family: $theme-font-family;
          color: #fff !important;
        }

        &.btn-export {
          background-color: $theme-color-orange !important;
          font-weight: 400;
          font-family: $theme-font-family;
          color: #fff !important;
        }
      }
    }

    .ngxdt-search {
      .ngxdt-search-wrapper {
        width: 300px;
        display: inline-flex;
        outline: 1px solid $search-color;
        margin: 0 1rem;
        align-items: center;
        // height: calc(100% - 2px);
        min-height: 24px;
        height: 24px;
        padding: 0;
        border-radius: $border-radius-1;
        font-size: 0.75rem;

        i {
          padding: 0 10px;
          color: $search-color;
          flex: 0 0 auto;
        }

        input {
          border: none;
          height: calc(100% - 4px);
          margin: 0;
          padding: 0;
          color: $search-color;
          flex: 1 1 auto;
          width: max-content;
          font-family: $theme-font-family;
          letter-spacing: normal;
        }

        .btn-search {
          margin: 0;
          padding: 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          border-radius: 0 $border-radius-1 $border-radius-1 0;
          min-width: 10px;
          flex: 0 0 auto;
          width: max-content;
        }

        button {
          margin: 0;
          padding: 0.25rem 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          //border-radius: 0 $border-radius-1 $border-radius-1 0;
          flex: 0 0 auto;
          width: max-content;
          transition: all 0.3s ease;

          i {
            color: $search-color;
          }

          &:hover {
            background-color: $search-color;

            i {
              color: white;
            }
          }
        }
      }
    }

    .ngxdt-select {
      height: 24px;
      color: $table-data-color;
      font-size: 0.75rem;
      font-family: $font-roboto;
      flex: 1 1 auto;
      margin: auto 14px;
      white-space: nowrap;

      label {
        margin: 0;
        margin-right: 0.25rem;
      }

      select {
        height: 100%;
        width: fit-content;
        border-radius: $border-radius-1;
        padding: 0 5px;
        margin: 0;
        outline: none;
        border: 1px solid $theme-color-gray !important;
        font-weight: 600;

        &.select-orange{
          background-color: $theme-color-orange !important;
          color: #fff;
          border: none !important;
          max-width: 130px;

          option{
            background-color: #fff;
            color: $theme-color-gray;
          }
        }
      }

      .date-range {
        display: flex;
        align-items: center;
      }
    }
  }

  .ngxdt-body {
    padding: 0.5rem 0 0 0;
    height: 100%;

    >* {
      font-size: 11px;
      letter-spacing: initial;
      color: $theme-color-gray;
    }
  }
}

//***/custom ngx datatable component***

//***ngx datatable***
.ngx-datatable.bootstrap {
  height: 100%;

  &>.visible {
    height: inherit;
    display: flex;
    flex-direction: column;
  }

  .datatable-header {
    width: 100% !important;

    .datatable-header-inner {
      font-weight: 600;
      color: $theme-color-gray;
      min-width: 100% !important;

      .datatable-row-center {
        min-width: 100% !important;

        .datatable-header-cell {
          padding: 0 0.75rem;
          border-bottom: 1px solid $theme-color-gray;

          .datatable-header-cell-label {
            font-size: 12px;
          }

          //to display first column data in center
          &.make-center .datatable-header-cell-template-wrap {
            display: block;
            text-align: center;
          }

          &:last-child {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  .datatable-body {
    // background-color: rgba($theme-color-gray-1, 0.4);
    overflow: auto;
    flex: 1 1 auto;
    margin-bottom: 5px;
    width: 100% !important;

    /* customize scrollbar */
    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $theme-color-gray-1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-color-gray-light;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-color-gray-light;
    }

    .datatable-scroll {
      display: initial;
      overflow-y: hidden;
      overflow-x: hidden;
      width: 100% !important;

      .datatable-row-wrapper {
        width: 100% !important;
      }
    }

    .empty-row {
      color: #6c757d;
      text-align: center;
    }

    .datatable-body-row {
      vertical-align: top;
      border-top: none;
      border-bottom: 1px solid #cecece;
      cursor: default !important;
      background-color: rgb(255, 255, 255) !important;
      color: $theme-color-gray;
      min-width: 100% !important;

      &.active {
        background-color: rgba($theme-color-gray-1, 0.7) !important;
        color: $theme-color-gray !important;
      }

      &:hover,
      &[ng-reflect-is-selected="true"]:hover {
        background-color: rgba($theme-color-gray-1, 0.4) !important;
        color: $theme-color-gray !important;
      }

      .datatable-body-cell {
        padding: 0 0.75rem;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: left;

        .datatable-body-cell-label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis !important;
        }

        .btn-edit {
          background-color: $search-color;
          border: none;
          line-height: 1;
          font-size: 10px;
          padding: 3px 7px;
          margin-top: -3px;

          &:hover {
            background-color: $table-data-color;
          }
        }

        a {
          color: $theme-color-gray;
          letter-spacing: 0;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $theme-color-orange;
          }
        }

        //to display column data in center
        &.make-center {
          justify-content: center;
        }
      }

      // &.datatable-row-even:not(.active) {
      //   background-color: #fff;
      // }
    }
  }

  .datatable-footer {
    background-color: white;

    .datatable-footer-inner {
      justify-content: center !important;

      .page-size-control {
        position: absolute;
        left: 40px;
        color: $theme-color-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 23px;
        gap: 4px;

        .page-size-dropdown {
          height: 22px;
          padding: 0 0.2rem;
          //line-height: 1.5;
          border-radius: 0;
          color: $theme-color-black-1;
          font-weight: 600;
          border: 1px solid $theme-color-gray-light;
          background-color: $theme-color-gray-1;
        }
      }

      .pagination-control {
        .pagination {
          height: 100%;
          margin-bottom: 1px;
        }

        .page-link {
          padding: 0 8px;
          color: $theme-color-gray !important;
          line-height: 22px;
          align-items: center;
          display: flex;
          justify-content: center;

          span {
            font-size: 15px;
            color: $theme-color-orange !important;
          }
        }

        .page-item.active .page-link {
          background-color: $theme-color-gray-1 !important;
          border-color: #dee2e6 !important;
          font-weight: 600;
        }
      }
    }
  }
}

//***/ngx datatable***


//*** hr ***
hr {
  border: none !important;
  border-bottom: 1px solid $theme-color-gray !important;
  margin: 0 !important;
}

.hr-red {
  border-bottom: 1px solid $theme-red !important;
}

.hr-light-blue {
  border-bottom: 1px solid $theme-light-blue !important;
}

.hr-gray {
  border-bottom: 1px solid $theme-color-gray !important;
}

.hr-orange {
  border-bottom: 1px solid $theme-color-orange !important;
}

//*** /hr ***

//Main Page header
.page-wrapper .page-header .header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 30px;
  position: relative;
}

.responsive-logo {
  display: none;
}

//checkbox pointer cursor
input[type="checkbox"] {
  cursor: pointer;
}

//notification Page
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p {
  color: $theme-gray !important;
  opacity: 100% !important;
}

.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p span {
  color: $theme-gray !important;
  opacity: 100% !important;
}

//Dashboard table
.table th,
.table td {
  border-top: 0 !important;
  vertical-align: middle !important;
  border-bottom: 1px solid $theme-gray !important;
  color: $theme-table;
  font-family: "Roboto";
}

//Input Border color on disabled and editable
.form-control {
  color: $theme-table;
  border: 1px solid #a1a1a1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:disabled {
  color: $theme-table;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

select {
  color: $theme-table;
  border: 1px solid #a1a1a1 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select:disabled {
  color: $theme-table;
  border-color: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.f-10 {
  font-size: 10px;
}

/*****checkbox*****/
.checkbox {

  &.checkbox-orange {
    input {
      margin-right: -32px;
      // position: absolute;
    }

    label {
      margin-bottom: 15px;
      margin-top: 0px;
    }

    label::before {
      border-color: $theme-color-orange;
    }

    input[type=checkbox]:checked+label::before {
      border-color: $theme-color-orange;
      color: $theme-color-orange;
    }

    &.filled {
      label::before {
        background-color: rgba($theme-color-orange, 0.5) !important;
      }
    }
  }

  &.checkbox-gray {
    input {
      // margin-right: -32px;
      position: absolute;
    }

    label {
      margin-bottom: 15px;
      margin-top: 0px;

    }

    label::before {
      border-color: $theme-color-gray;
    }

    input[type=checkbox]:checked+label::before {
      border-color: $theme-color-gray;
      color: $theme-color-gray;
    }

    &.filled {
      label::before {
        background-color: rgba($theme-color-gray, 0.5) !important;
      }
    }
  }

  input[type=checkbox]:checked+label::before {
    line-height: 1.5;
  }

  label {
    padding-left: 32px;
    margin-top: -15px;

    &::before {
      margin-left: 0;
    }
  }
}

/*****end checkbox*****/

//*****breadcrumb*****
.page-wrapper .page-body-wrapper .page-title {
  padding-top: 5px !important;
  padding-bottom: 10px !important;

  .row h3 {
    font-size: 16px;
  }

  .breadcrumb li.breadcrumb-item {
    font-size: 13px !important;
    font-weight: 500;
    font-family: $theme-font-family !important;
    letter-spacing: 0 !important;
    color: $theme-color-gray-light !important;

    +.breadcrumb-item:before {
      content: "\\";
      color: $theme-color-gray-light;
      float: none !important;
    }

    a svg {
      width: 14px !important;
      height: 14px !important;
      color: $theme-color-gray-light !important;
      vertical-align: middle !important;
    }

    &.active,
    &.active:before {
      color: $theme-color-gray !important;
    }
  }
}

//***** /breadcrumb*****

//*****Profile*****
.user-profile .hovercard .cardheader {
  background-size: cover;
  background-position: 10%;
  height: 270px;
}

//*****/Profile*****

//*****Help btn tooltip*****
.help-btn {
  i {
    font-size: 12px;
    color: $theme-color-orange;
    opacity: 0.8;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.help-tooltip {
  width: 300px !important;

  .tooltip-inner {
    font-size: 11px !important;
    max-width: 400px !important;
    text-align: left !important;

    span {
      display: block;
    }
  }
}

//*****/Help btn tooltip*****

//***** Reported Content *****
.reported-content {
  background-color: #000;
  padding: 10px;
  margin: 0;

  * {
    cursor: default;
    color: #fff;
  }

  .image-thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    border: none;
    background-color: #fff;
    padding: 6px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .username {
    flex: 1 1 auto;
    color: $theme-color-orange;
    font-family: $theme-font-family-serial-medium;
    font-size: 12px;
  }

  .divider {
    border: none;
    border-top: solid 1px $theme-color-gray;
    margin: 5px 10px;
  }

  .content-post {

    .post-header {
      padding: 5px 0;

      .post-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        .post-creator-profile-img {
          padding: 0 !important;
        }

        .dot-menu {
          color: #fff;
          font-size: 18px;
          cursor: default;
        }
      }
    }

    .post-campaign-widget {
      margin-top: 1rem;

      .campaign-widget-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        .campaign-details {
          flex: 1 1 auto;
          margin-right: 20px;
          align-self: flex-end;

          .campaign-name {
            font-size: 15px;
            font-family: $theme-font-family-serial-bold;
          }

          .campaign-progress {

            .campaign-progressbar {
              width: 100%;

              .progress-bar {
                border-radius: 4px !important;
                background: rgb(254, 185, 19) !important;
                background: linear-gradient(0deg, rgba(254, 185, 19, 1) 0%, rgba(252, 171, 22, 1) 80%, rgba(245, 122, 33, 1) 100%) !important;
              }
            }

            .campaign-status {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 2px;

              .campaign-completion-value {
                font-size: 9px;
                font-family: $theme-font-family-serial-regular;
              }

              .campaign-end-date {
                font-size: 9px;
                font-family: $theme-font-family-serial-regular;
              }
            }
          }
        }

        .campaign-activity-logo {
          width: 45px;
          height: 45px;
          border-radius: 17px;
        }

        .right-arrow-icon {
          padding-left: 8px;

          svg {
            vertical-align: text-bottom !important;
          }
        }

        .campaign-ngo-logo {
          width: 45px;
          height: 45px;
          border-radius: 17px;
        }
      }
    }

    .post-image {
      margin: 10px -10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .post-shared-link {
      font-size: 11px;
      color: blue;
      text-decoration: underline;
    }

    .post-description {
      line-height: 1;
      padding: 8px 0;

      .post-description-text {
        color: #fff;
        font-family: $theme-font-family-serial-light;
        font-size: 12px;
        padding-left: 10px;
      }
    }

    .post-footer {
      padding: 10px 0;

      .post-footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 9px;
        font-family: $theme-font-family-serial-medium;
        color: #fff;

        .post-footer-left {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;

          .post-likes {
            display: flex;
            align-items: center;
            column-gap: 4px;

            .count {
              color: $theme-color-orange;
            }
          }

          .post-comments {
            display: flex;
            align-items: center;
            column-gap: 4px;

            .count {
              color: $theme-color-orange;
            }
          }

          .post-view-campaign-btn {
            width: 80px;
            height: 15px;
            padding: 0;
            font-size: 9px;
            line-height: 1.8;
            border-radius: 6px;
            background-color: $theme-color-orange;
            font-family: $theme-font-family-serial-medium;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            vertical-align: middle;
          }
        }

        .post-footer-right {
          .post-timestamp {
            font-family: $theme-font-family-serial-light;
            letter-spacing: 0.5px;
          }
        }

      }
    }
  }

  .content-comment {
    padding: 10px 0;

    .content-comment-wrapper {
      display: flex;
      align-items: flex-start;
      column-gap: 8px;

      .comment-creator-profile-img {
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 13px;
      }

      .comment-details {
        flex: 1 1 auto;
        line-height: 1;
        padding-top: 2px;

        .comment-description {
          margin-bottom: 30px;

          .comment-text {
            color: $theme-color-gray-light;
            font-family: $theme-font-family-serial-light;
            font-size: 12px;
            padding-left: 10px;
          }
        }

        .comment-timestamp {
          color: $theme-color-gray-light;
          font-family: $theme-font-family-serial-light;
          letter-spacing: 0.5px;
          font-size: 9px;
        }
      }
    }
  }
}

//***** End Reported Content *****

main {
  height: calc(100vh - 92px);

  .content-wrapper {
    height: 100%;

    >.card {
      height: calc(100% - 35px);
      padding: 1rem 0;
      margin: 0;

      .card-body {
        padding: 0;
        overflow-y: hidden;
        overflow-x: auto;
      }
    }
  }

}


select>option:hover {
  background-color: #FF4714 !important;
  color: #fff !important;
}

select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px #FF4714 inset;
  background: #FF4714 !important;
  color: #fff !important;
}

select:focus>option:checked {
  background: #FF4714 !important;
  color: #fff !important;
}