//font-family
@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900&amp;display=swap) format("truetype");
}

@font-face {
    font-family: "Montreal-Regular";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/Montreal-Regular.ttf) format("truetype");
}


@font-face {
    font-family: "Montreal-Bold";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/Montreal-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Montreal-Serial-Light";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/montreal_serial-light.otf) format("OpenType");
}

@font-face {
    font-family: "Montreal-Serial-Regular";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/montreal_serial-regular.otf) format("OpenType");
}

@font-face {
    font-family: "Montreal-Serial-Medium";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/montreal_serial-medium.otf) format("OpenType");
}

@font-face {
    font-family: "Montreal-Serial-Bold";
    src: local("Montreal"),
        url(./assets/fonts/Montreal/montreal_serial-bold.otf) format("OpenType");
}

// Do not use variables
$table-data-color: #707070;
$search-color:#707070;
$theme-table:#707070;
$radio-green: #C3DA8C;

// use below variables for knobl

/* Font-size */
$theme-font-size-xxs:6px;
$theme-font-size-xs:8px;
$theme-font-size-sm:10px;
$theme-font-size:11px;
$theme-font-size-md:12px;
$theme-font-size-lg:14px;
$theme-font-size-xl:15px;
$theme-font-size-xxl:16px;
$theme-font-size-xxxl:18px;
$theme-font-size-20:20px;
$theme-font-size-24:24px;
$theme-font-size-30:30px;
$theme-font-size-40:40px;

/* height - width */
$top-header-height: 60px;
$sidebar-width: 230px;

/* border - border-radius */
$border-radius-1: 0.25rem;
$border-radius-2: 0.50rem;

/* colors */
$theme-gray: #a1a1a1;
$theme-red : #EF6A65;
$theme-light-blue: #5377B5;
$theme-color-orange: #FF4714;
$theme-color-gray: #707070;
$theme-color-gray-light: #a1a1a1;
$theme-color-gray-lighter: #dee2e6;
$theme-color-gray-1: #EFEFEF;
$theme-color-black-1: #171717;
$theme-content-background-color: #efefef;

/* font-family */
$font-roboto: Roboto, sans-serif;
$theme-font-family: Montreal-Regular, sans-serif;
$theme-font-family-bold: Montreal-Bold, sans-serif;
$theme-font-family-serial-light: Montreal-Serial-Light, sans-serif;
$theme-font-family-serial-regular: Montreal-Serial-Regular, sans-serif;
$theme-font-family-serial-medium: Montreal-Serial-Medium, sans-serif;
$theme-font-family-serial-bold: Montreal-Serial-Bold, sans-serif;

// $font-roboto: sans-serif;
// $theme-font-family: sans-serif;
// $theme-font-family-bold: sans-serif;
// $theme-font-family-serial-light: sans-serif;
// $theme-font-family-serial-regular: sans-serif;
// $theme-font-family-serial-medium: sans-serif;
// $theme-font-family-serial-bold: sans-serif;